// @import-normalize; /* bring in normalize.css styles */
/* rest of app styles */
@import "normalize.scss";

/* This is Recommended reset from CRA from https://create-react-app.dev/docs/adding-css-reset */

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// @font-face {
// 	font-family: SarabunRegular;
// 	src: url("./fonts/Sarabun-Regular.ttf");
// }

// @font-face {
// 	font-family: SarabunBold;
// 	src: url("./fonts/Sarabun-Bold.ttf");
// }

@font-face {
	font-family: db_adman_x;
	src: url("./fonts/db_adman_x.ttf");
}

body {
	font-family: db_adman_x;
	font-size: 1.3rem;
}

b,
h2 {
	font-family: db_adman_x;
}

a {
	text-decoration: none;
	color: black;
}

.error {
	color: red;
	font-size: 14px;
	margin-left: 5px;
}

a:hover,
a:active,
a:focus,
input:hover,
input:active,
input:focus,
button:hover,
button:active,
button:focus {
	outline: 0;
}