/* === Global === */
$hover-color: #218192;
$padding: 10vw;


/* === Nav Bar === */ 
$top-bar-color: #009473;
$top-bar-font-size: 10px;

$nav-bar-font-size:  calc(10px + 0.5vw);
$nav-bar-font-size: 16px;


/* == Home Page == */

/* https://stackoverflow.com/questions/14431411/pure-css-to-make-font-size-responsive-based-on-dynamic-amount-of-characters*/
// This needs white space!! https://www.thetopsites.net/article/50430234.shtml
$home-s1-font-size: calc(10px + 0.5vw); 
