// START ===> RESET TAG CSS
// table, caption, tbody, tfoot, thead, tr, th, td {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
//     background-color: white;
// }
tr:nth-child(even) {
    // background-color: white;
}

// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }
// END ===> RESET TAG CSS



body {
    margin: 0;
    padding: 0;
    // background-color: #FAFAFA;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.printButton {
    color: white;
    border: 0.5px solid #009473;
    background-color: #009473;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 40px;
}

.margin5 {
    margin: 3px;
    font-size: 11px;
}

.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 1cm;
    margin: 30px auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

tr.borderTop td {
    border-top: 1px solid rgb(216, 216, 216);
    padding: 0;
}

.subpage {
    // border: 2px black solid;
    // min-height: 266mm;
    // height: 100%;

    table {
        border: 1px solid black;
        width: 100%;
        // border-radius: 10px;
    }

    th {
        white-space: nowrap;
    }

    th,
    td {
        padding: 10px;
        text-align: left;
        // font-size: 12px;
        font-size: 1rem;
    }

    tbody {
        vertical-align: top;
    }

    textarea {
        width: 100%;
        padding: 10px;
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }


    textarea {
        border: none;
        resize: none;
        overflow: hidden;
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.headPage {
    border: 1px solid black;
    // border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    height: fit-content;
    // font-size: 15px;
    font-size: 1.3rem;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    div {
        // font-size: 12px;
        font-size: 1rem;
    }
}

.box,
.boxNote {
    border: 1px solid black;
    // border-radius: 10px;
    padding: 10px 20px;
    height: 150px;
    gap: 15px;

    p {
        margin: 0 0 8px 0;
    }
}

.boxNote {
    padding: 5px 5px;
}

.taxWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    div {
        // font-size: 12px;
        font-size: 1rem;
    }
}

.taxBox {
    border: 1px solid black;
    // border-radius: 10px;
    padding: 10px 20px;
    padding-bottom: 5px;
    height: fit-content;
    gap: 15px;

    p {
        margin: 0 0 8px 0;
    }
}

.col50 {
    flex-basis: 50%;
}

.desciption {
    font-size: 12px;
    color: #5f5f5f;
    padding: 0px 0px;
}